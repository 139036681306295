import * as React from 'react';
import {useState} from 'react';

import {Alerts} from '../../../ui/Alerts/Alerts';
import {EmptyState} from '../../../ui/EmptyState/EmptyState';
import {GaItemListName} from '../../../../client/ga/ga-ecommerce.functions';
import {TopProductsSection} from './TopProductsSection';
import {TopProduct} from '../../../items/item.class';
import {User} from '../../../users/user.class';
import Button from '../../../ui/Buttons/Button';

interface TopProductsProps {
    closeMegaMenu: () => void;
    getTopProductsErr: boolean;
    loadingTopProducts: boolean;
    isQuickAdd?: boolean;
    setShowAccountFinderModal: React.Dispatch<React.SetStateAction<boolean>>;
    topProductsCol1: TopProduct[];
    topProductsCol2: TopProduct[];
    user: User;
}

export const TopProducts = ({
    closeMegaMenu,
    getTopProductsErr,
    loadingTopProducts,
    isQuickAdd = false,
    setShowAccountFinderModal,
    topProductsCol1,
    topProductsCol2,
    user,
}: TopProductsProps) => {
    const [addToOrderError, setAddToOrderError] = useState(``);
    const gaItemListName: GaItemListName = `header_most_frequently_purchased`;

    /**
     * Triggers display of AccountFinderModal
     */
    const showAccountFinder = () => {
        setShowAccountFinderModal(true);
        closeMegaMenu();
    };

    /**
     * Templates
     */
    if (getTopProductsErr) {
        return <EmptyState message="We're sorry. An error has occurred." />;
    } else if (user.isAccountLevel()) {
        if (loadingTopProducts) {
            return <EmptyState message="Loading..." />;
        }
        return (
            <>
                {addToOrderError && (
                    <Alerts
                        message={addToOrderError}
                        variant="danger"
                    />
                )}
                {topProductsCol1.length > 0 ? (
                    <div className="tw-grid tw-grid-cols-2 tw-gap-5">
                        {topProductsCol1.length > 0 && (
                            <TopProductsSection
                                closeMegaMenu={closeMegaMenu}
                                gaItemListName={gaItemListName}
                                isQuickAdd={isQuickAdd}
                                setAddToOrderError={setAddToOrderError}
                                topProducts={topProductsCol1}
                                user={user}
                            />
                        )}
                        {topProductsCol2.length > 0 && (
                            <TopProductsSection
                                closeMegaMenu={closeMegaMenu}
                                gaItemListName={gaItemListName}
                                isQuickAdd={isQuickAdd}
                                setAddToOrderError={setAddToOrderError}
                                topProducts={topProductsCol2}
                                user={user}
                            />
                        )}
                    </div>
                ) : (
                    <EmptyState message="No purchased products currently available." />
                )}
            </>
        );
    } else if (user.isLoggedIn()) {
        return (
            <EmptyState
                message={
                    <div className="tw-text-center">
                        <p>Please Log Into The Account You Want To Order For</p>
                        <Button
                            onClick={showAccountFinder}
                            size="sm"
                            variant="link"
                        >
                            Select Account
                        </Button>
                    </div>
                }
            />
        );
    } else {
        return <EmptyState message={<a href="/login">Log In to view your products.</a>} />;
    }
};
