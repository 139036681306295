import * as React from 'react';
import {faCircleUser} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useState} from 'react';

import {AccountFinderModal} from '../../authentication/AccountFinderModal/AccountFinderModal';
import {BodyScroll} from '../../ui/ScrollHandler';
import {FlyoutMenu} from '../../ui/Flyouts/FlyoutMenu';
import {ImpError} from '../../../client/imp-error/imp-error.class';
import {MyAccountContainer, MyAccountFlyoutStyle} from './MyAccountFlyout.style';
import {OrdersService} from '../../../client/orders/orders.service';
import {OrdersWorkflow} from '../../../client/orders/orders.workflow';
import {UserStateService} from '../../../client/users/user-state.service';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';
import Button from '../../ui/Buttons/Button';
import {EmptyState} from '../../ui/EmptyState/EmptyState';

interface MyAccountFlyoutProps {
    user: User;
}

export const MyAccountFlyout = ({user}: MyAccountFlyoutProps) => {
    const [hydrated, setHydrated] = React.useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [showAccountFinderModal, setShowAccountFinderModal] = useState(false);
    const componentName = `my-account-flyout-component`;
    const ordersService: OrdersService = useService(`ordersService`);
    const ordersWorkflow: OrdersWorkflow = useService(`ordersWorkflow`);
    const userStateService: UserStateService = useService(`userStateService`);

    useEffect(() => {
        setHydrated(true);
    }, []);

    /**
     * Creates new order
     */
    const createNewOrder = () => {
        ordersWorkflow.createOrderNavOrderDetail(componentName).subscribe({
            error: (createOrderErr: ImpError) => {
                alert(createOrderErr.message);
            },
        });
    };

    // Process queued actions
    useEffect(() => {
        if (userStateService.hasPendingAction(componentName, `createOrder`)) {
            createNewOrder();
        }
        // Only run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Toggles display of My Account menu
     */
    const toggleMyAccountFlyout = () => {
        if (user.isLoggedIn()) {
            setMenuOpen(true);
            BodyScroll(false);
        }
    };

    /**
     * Template
     */
    return (
        <>
            <MyAccountContainer>
                <span
                    className="tw-flex tw-items-center tw-cursor-pointer"
                    data-e2e="myAccountLink"
                    tabIndex={0}
                    onClick={() => {
                        toggleMyAccountFlyout();
                    }}
                    onFocus={() => {
                        toggleMyAccountFlyout();
                    }}
                >
                    <FontAwesomeIcon
                        className="tw-mr-2 tw-text-gray-600"
                        icon={faCircleUser}
                        size="lg"
                    />{' '}
                    <span className="tw-inline tw-text-gray-600 tw-text-base tw-font-normal tw-mb-0">Account</span>
                </span>
            </MyAccountContainer>
            <MyAccountFlyoutStyle>
                <FlyoutMenu
                    e2e="manageAccountFlyout"
                    onClose={() => {
                        setMenuOpen(false);
                        BodyScroll(true);
                    }}
                    show={menuOpen}
                    title="Welcome"
                >
                    <div className="tw-p-2">
                        <>
                            {!user.activeAccount ? (
                                <EmptyState
                                    message={
                                        <div>
                                            You must&nbsp;
                                            <a
                                                className="tw-cursor-pointer tw-text-blue-200"
                                                onClick={() => {
                                                    setMenuOpen(false);
                                                    BodyScroll(true);
                                                    setShowAccountFinderModal(true);
                                                }}
                                            >
                                                select an account
                                            </a>
                                            &nbsp;to see your open orders
                                        </div>
                                    }
                                />
                            ) : (
                                <div
                                    className="tw-visible"
                                    data-e2e="myAccountDropdown"
                                >
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-3 tw-px-2">
                                        {user.isLoggedIn() && (
                                            <>
                                                <div className="tw-w-full tw-mb-6">
                                                    <ul className="tw-text-black tw-text-base tw-font-normal tw-list-none tw-m-0 tw-p-0">
                                                        <li className="tw-mt-5 tw-text-xl tw-pb-2">
                                                            <h5 className="tw-font-extrabold tw-text-xl tw-mb-2 tw-mt-0">My Orders</h5>
                                                            <ul className="tw-list-none tw-mt-2.5 tw-mb-0 tw-mx-0 tw-p-0">
                                                                {user.canOrder() && (
                                                                    <>
                                                                        <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                            <a
                                                                                className="tw-block tw-text-black hover:tw-text-black"
                                                                                href="/orders"
                                                                            >
                                                                                Order Review
                                                                            </a>
                                                                        </li>
                                                                        {!user.isPunchout && (
                                                                            <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                                <a
                                                                                    className="tw-block tw-text-black hover:tw-text-black"
                                                                                    href="/manageautoreorders"
                                                                                >
                                                                                    Auto-Reorder Items
                                                                                </a>
                                                                            </li>
                                                                        )}
                                                                        <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                            <a
                                                                                className="tw-block tw-text-black hover:tw-text-black"
                                                                                href="/special-order"
                                                                            >
                                                                                Special Order Review
                                                                            </a>
                                                                        </li>
                                                                    </>
                                                                )}
                                                                {user.hasOrderApprovalAccess && (
                                                                    <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                        <a
                                                                            className="tw-block tw-text-black hover:tw-text-black"
                                                                            href="/orders/approval"
                                                                        >
                                                                            Order Approval
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                {user.canOrder() && (
                                                                    <>
                                                                        <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                            <a
                                                                                className="tw-block tw-text-black hover:tw-text-black"
                                                                                href="/returns?numMonths=6"
                                                                            >
                                                                                My Returns
                                                                            </a>
                                                                        </li>
                                                                        {user.hasActiveOrder() && !user.isPunchout && hydrated ? (
                                                                            <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                                <a
                                                                                    className="tw-block tw-text-black hover:tw-text-black"
                                                                                    href={`/orders/${ordersService.currentOrderNumber}/checkout`}
                                                                                >
                                                                                    Check Out Order {ordersService.currentOrderNumber}
                                                                                </a>
                                                                            </li>
                                                                        ) : (
                                                                            <li>
                                                                                <Button
                                                                                    className="tw-text-gray-600"
                                                                                    e2e="createOrderAccountFlyout"
                                                                                    onClick={() => {
                                                                                        setMenuOpen(false);
                                                                                        createNewOrder();
                                                                                    }}
                                                                                    size="sm"
                                                                                    variant="link"
                                                                                >
                                                                                    Create Order
                                                                                </Button>
                                                                            </li>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </ul>
                                                        </li>
                                                        <li className="tw-mt-5 tw-text-xl tw-pb-2">
                                                            <h5 className="tw-font-extrabold tw-text-xl tw-mb-2 tw-mt-0">
                                                                Invoices & Statements
                                                            </h5>
                                                            <ul className="tw-list-none tw-mt-2.5 tw-mb-0 tw-mx-0 tw-p-0">
                                                                {user.canInvoice() && (
                                                                    <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                        <a
                                                                            className="tw-block tw-text-black hover:tw-text-black"
                                                                            href="/invoices"
                                                                        >
                                                                            Invoice Review
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                {user.hasARAccess() && (
                                                                    <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                        <a
                                                                            className="tw-block tw-text-black hover:tw-text-black"
                                                                            href="/ar"
                                                                        >
                                                                            Invoice Payment
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                {user.hasSalesAccess() && user.canViewPrice() && (
                                                                    <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                        <a
                                                                            className="tw-block tw-text-black hover:tw-text-black"
                                                                            href="/discounts"
                                                                        >
                                                                            Discount Review
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                {user.hasJobCosting() && (
                                                                    <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                        <a
                                                                            className="tw-block tw-text-black hover:tw-text-black"
                                                                            href="/jobcosting"
                                                                        >
                                                                            Repair Order Review
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                {user.hasRepairOrder() && (
                                                                    <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                        <a
                                                                            className="tw-block tw-text-black hover:tw-text-black"
                                                                            href="/ro"
                                                                        >
                                                                            Repair Orders
                                                                        </a>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </li>
                                                        <li className="tw-mt-5 tw-text-xl tw-pb-2">
                                                            <h5 className="tw-font-extrabold tw-text-xl tw-mb-2 tw-mt-0">Reporting</h5>
                                                            <ul className="tw-list-none tw-mt-2.5 tw-mb-0 tw-mx-0 tw-p-0">
                                                                {user.hasSalesAccess() && user.canViewPrice && (
                                                                    <>
                                                                        <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                            <a
                                                                                className="tw-block tw-text-black hover:tw-text-black"
                                                                                href="/spend-overview"
                                                                            >
                                                                                Spend Overview Dashboard
                                                                            </a>
                                                                        </li>
                                                                    </>
                                                                )}
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="tw-w-full tw-mb-6">
                                                    <ul className="tw-text-black tw-text-base tw-font-normal tw-list-none tw-m-0 tw-p-0">
                                                        <li className="tw-mt-5 tw-text-xl tw-pb-2">
                                                            <h5 className="tw-font-extrabold tw-text-xl tw-mb-2 tw-mt-0">Reorder</h5>
                                                            <ul className="tw-list-none tw-mt-2.5 tw-mb-0 tw-mx-0 tw-p-0">
                                                                {user.canOrder() && (
                                                                    <>
                                                                        <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                            <a
                                                                                className="tw-block tw-text-black hover:tw-text-black"
                                                                                href="/order-history-items"
                                                                            >
                                                                                Purchased Products
                                                                            </a>
                                                                        </li>
                                                                        <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                            <a
                                                                                className="tw-block tw-text-black hover:tw-text-black"
                                                                                href="/fileimport/"
                                                                            >
                                                                                File Import
                                                                            </a>
                                                                        </li>
                                                                        <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                            <a
                                                                                className="tw-block tw-text-black hover:tw-text-black"
                                                                                href="/lists/"
                                                                            >
                                                                                Lists
                                                                            </a>
                                                                        </li>
                                                                    </>
                                                                )}
                                                                {user.canOrder() && (
                                                                    <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                        <a
                                                                            className="tw-block tw-text-black hover:tw-text-black"
                                                                            href="/quickadd"
                                                                        >
                                                                            Quick-Add
                                                                        </a>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </li>
                                                        <li className="tw-mt-5 tw-text-xl tw-pb-2">
                                                            <h5 className="tw-font-extrabold tw-text-xl tw-mb-2 tw-mt-0">My Shop</h5>
                                                            <ul className="tw-list-none tw-mt-2.5 tw-mb-0 tw-mx-0 tw-p-0">
                                                                {user.isAccountLevel() && (
                                                                    <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                        <a
                                                                            className="tw-block tw-text-black hover:tw-text-black"
                                                                            href="/my-shop"
                                                                        >
                                                                            Locations
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                    <a
                                                                        className="tw-block tw-text-black hover:tw-text-black"
                                                                        href="/my-shop/inventory"
                                                                    >
                                                                        Inventory Items
                                                                    </a>
                                                                </li>
                                                                <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                    <a
                                                                        className="tw-block tw-text-black hover:tw-text-black"
                                                                        href="/my-shop/steel-equipment"
                                                                    >
                                                                        Steel Equipment
                                                                    </a>
                                                                </li>
                                                                <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                    <a
                                                                        className="tw-block tw-text-black hover:tw-text-black"
                                                                        href="/my-sds"
                                                                    >
                                                                        Safety Data Sheets
                                                                    </a>
                                                                </li>
                                                                <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                    <a
                                                                        className="tw-block tw-text-black hover:tw-text-black"
                                                                        href="/my-shop/backsheets"
                                                                    >
                                                                        Backsheets
                                                                    </a>
                                                                </li>
                                                                {user.isFieldService() && (
                                                                    <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                        <a
                                                                            className="tw-block tw-text-black hover:tw-text-black"
                                                                            href="/fs-photo-uploader"
                                                                        >
                                                                            Upload Photos
                                                                        </a>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </li>
                                                        <li className="tw-mt-5 tw-text-xl tw-pb-2">
                                                            <h5 className="tw-font-extrabold tw-text-xl tw-mb-2 tw-mt-0">
                                                                Account Management
                                                            </h5>
                                                            <ul className="tw-list-none tw-mt-2.5 tw-mb-0 tw-mx-0 tw-p-0">
                                                                {user.canSwitchAccount() && (
                                                                    <li
                                                                        className="tw-text-base tw-font-light tw-leading-10 tw-mt-0"
                                                                        style={{lineHeight: 1}}
                                                                    >
                                                                        <Button
                                                                            className="tw-text-gray-600"
                                                                            onClick={() => {
                                                                                setMenuOpen(false);
                                                                                BodyScroll(true);
                                                                                setShowAccountFinderModal(true);
                                                                            }}
                                                                            size="sm"
                                                                            variant="link"
                                                                        >
                                                                            <>
                                                                                Switch Account
                                                                                <div className="tw-text-sm tw-font-normal tw-leading-[1.3] tw-text-left">
                                                                                    Current:{' '}
                                                                                    {user.activeAccount
                                                                                        ? user.activeAccount
                                                                                        : 'No Account Selected'}{' '}
                                                                                </div>
                                                                            </>
                                                                        </Button>
                                                                    </li>
                                                                )}
                                                                {user.isAccountLevel() && (
                                                                    <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                        <a
                                                                            className="tw-block tw-text-black hover:tw-text-black"
                                                                            href="/account-info"
                                                                        >
                                                                            Account Review
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                    <a
                                                                        className="tw-block tw-text-black hover:tw-text-black"
                                                                        href="/account#change-password"
                                                                    >
                                                                        Change Password
                                                                    </a>
                                                                </li>
                                                                <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                    <a
                                                                        className="tw-block tw-text-black hover:tw-text-black"
                                                                        href="/email-preferences"
                                                                    >
                                                                        Email Preferences
                                                                    </a>
                                                                </li>
                                                                {user.invoicePrefAccess && (
                                                                    <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                        <a
                                                                            className="tw-block tw-text-black hover:tw-text-black"
                                                                            href="/invoice-delivery-preferences"
                                                                        >
                                                                            Invoice Delivery Preferences
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                <li className="tw-text-base tw-font-light tw-leading-10 tw-mt-0">
                                                                    <a
                                                                        className="tw-block tw-text-black hover:tw-text-black"
                                                                        href="/payment-methods"
                                                                    >
                                                                        Payment Methods
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="tw-mb-4 tw-px-2">
                                        <div className="tw-grid tw-grid-cols-1 tw-flex-grow tw-w-full tw-relative">
                                            <Button
                                                className="tw-px-2 tw-w-full"
                                                href="/account"
                                                size="lg"
                                                variant="secondary"
                                            >
                                                View My Account
                                            </Button>
                                            <Button
                                                className="tw-px-2 tw-mt-4 tw-w-full"
                                                href="/logout"
                                                size="lg"
                                                variant="outline-secondary"
                                            >
                                                Log out
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    </div>
                </FlyoutMenu>
            </MyAccountFlyoutStyle>
            <AccountFinderModal
                onHide={() => setShowAccountFinderModal(false)}
                selectAccountMode={user.useSelectAccountMode()}
                show={showAccountFinderModal}
                user={user}
            />
        </>
    );
};
