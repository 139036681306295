import * as React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useRef, useState} from 'react';

import Button from '../../ui/Buttons/Button';
import {Alerts} from '../../ui/Alerts/Alerts';
import {ContentModal} from '../../ui/modals/ContentModal/ContentModal';
import {ListPickerRes} from '../list.class';
import {Lists, UserList} from '../lists/lists.class';
import {ListsService} from '../../../client/lists/lists.service';
import {ProductImage} from '../../ui/ProductImage/ProductImage';
import {Select, SelectOption} from '../../ui/forms/Select/Select';
import {useService} from '../../react/ServiceContext';

interface SelectListFormInputs {
    listId: string;
}

interface AddToListModalProps {
    getListId: (listPickerRes: ListPickerRes) => void;
    itemDesc: string;
    itemImage: string;
    onClose: () => void;
    showCreateNewListModal: () => void;
    show: boolean;
    userLists: UserList[];
}

export const AddToListModal = ({getListId, itemDesc, itemImage, onClose, showCreateNewListModal, show, userLists}: AddToListModalProps) => {
    const [errorMessage, setErrorMessage] = useState(``);
    const formRef = useRef(null);
    const listsService: ListsService = useService(`listsService`);
    const useFormReturn = useForm<SelectListFormInputs>();

    /**
     * Formats and returns options for use in the listId select dropdown
     */
    const getListOptions = (): SelectOption[] => {
        const _formattedUserLists: UserList[] = [];
        const _listOptions: SelectOption[] = [];
        let _userHasDefaultList = false;

        // Review lists
        if (userLists && userLists.length) {
            for (const userList of userLists) {
                // Filter to only have editable lists
                if (userList.canEdit) {
                    _formattedUserLists.push(userList);
                }

                // Determine if user has a "My Favorites" list already
                if (userList.defaultList) {
                    _userHasDefaultList = true;
                }
            }
        }

        // Sort so that defaultList is first
        if (_userHasDefaultList) {
            _formattedUserLists.sort((userList1, userList2) => {
                if (userList1.defaultList > userList2.defaultList) {
                    return -1;
                }
                if (userList1.defaultList < userList2.defaultList) {
                    return 1;
                }
            });
        } else {
            // Create a default option if one doesn't exist already
            _listOptions.push({key: `newDefaultList`, value: `My Favorites`});
        }

        // Build list options
        for (const userList of _formattedUserLists) {
            _listOptions.push({key: userList.listId, value: userList.desc});
        }

        // Return list options
        return _listOptions;
    };

    /**
     * Returns the listId of the list that should be selected by default
     */
    const getSelectedListId = (): string => {
        // If the user is working with an active list, return that
        if (listsService.currentListId) {
            return listsService.currentListId;
        }

        // Else return the most recently updated list
        if (userLists.length) {
            const sortedUserLists = Lists.sortUserLists(userLists, `updated_z`);
            return sortedUserLists[0].listId;
        }

        // Default to returning an empty string
        return ``;
    };

    /**
     * Returns user choice of list to use for workflow
     * @param selectListFormInputs
     */
    const pickList = (selectListFormInputs: SelectListFormInputs) => {
        // Get selectedListName to return
        const listOptions = getListOptions();
        let selectedListName = ``;
        for (const listOption of listOptions) {
            if (selectListFormInputs.listId === listOption.key) {
                selectedListName = listOption.value;
            }
        }

        // Return ListPickerRes and close modal
        getListId({listId: selectListFormInputs.listId, listName: selectedListName});
        onClose();
    };

    /**
     * Handles remote form submission
     */
    const submitForm = () => {
        formRef.current?.dispatchEvent(new Event(`submit`, {bubbles: true, cancelable: true}));
    };

    /**
     * Template
     */
    return (
        <ContentModal
            footer={
                <div className="tw-grid lg:!tw-block tw-w-full lg:tw-w-auto">
                    <Button
                        modal={true}
                        modalOrderChangeButton1={true}
                        onClick={() => {
                            onClose();
                        }}
                        size="md"
                        variant="outline-secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        modal={true}
                        modalOrderChangeButton2={true}
                        onClick={submitForm}
                        size="md"
                        variant="secondary"
                    >
                        Save
                    </Button>
                </div>
            }
            onClose={onClose}
            show={show}
            title="Add to List"
        >
            <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3">
                <Alerts
                    message={errorMessage}
                    variant="danger"
                />
                <div className="tw-w-full tw-relative tw-pb-4 tw-flex tw-justify-center">
                    {itemImage && (
                        <ProductImage
                            altText={itemDesc}
                            size={120}
                            src={itemImage}
                        />
                    )}
                </div>
                <div className="tw-w-full tw-relative tw-col-span-1 sm:tw-col-span-2">
                    <FormProvider {...useFormReturn}>
                        <form
                            onSubmit={(event) => {
                                setErrorMessage(``);
                                useFormReturn
                                    .handleSubmit(pickList)(event)
                                    .catch((handleSubmitErr) => {
                                        setErrorMessage(handleSubmitErr.message);
                                    });
                            }}
                            ref={formRef}
                        >
                            <Select
                                className="tw-w-full"
                                defaultValue={getSelectedListId()}
                                id="addToListModal__selectListDropdown"
                                label="Select a List"
                                name="listId"
                                options={getListOptions()}
                                {...useFormReturn.register(`listId`)}
                            />
                        </form>
                        <div className="tw-mb-4">
                            <Button
                                className="tw-pl-0 !tw-text-base"
                                onClick={() => {
                                    showCreateNewListModal();
                                }}
                                type="button"
                                variant="link"
                            >
                                Create New List
                            </Button>
                        </div>
                    </FormProvider>
                </div>
            </div>
        </ContentModal>
    );
};
