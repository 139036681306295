import * as React from 'react';
import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useMemo, useState} from 'react';

import PromotionTracker from '../../analytics/promotion-tracker/PromotionTracker';
import {ErrorModal} from '../../error-handling/ErrorModal';
import {GaItemListName} from '../../../client/ga/ga-ecommerce.functions';
import {gaLegacyCustomEvent} from '../../../client/ga/ga-legacy.functions';
import {GlobalMessaging, Slice} from '../../cms/cms.types';
import {ImpError} from '../../../client/imp-error/imp-error.class';
import {ImpUrl} from '../../../client/imp-url/imp-url.class';
import {ItemToAdd} from '../../order-items/order-items.class';
import {OrderItemsWorkflow} from '../../../client/order-items/order-items.workflow';
import {OrdersService} from '../../../client/orders/orders.service';
import {Order} from '../../orders/order.class';
import {PromoNotMetModal} from '../../promos/PromoNotMetModal';
import {PromosService} from '../../../client/promos/promos.service';
import {RibbonAd} from '../campaign-ads/campaign-ads.class';
import {SHIPPING_SAVER} from '../../items/item.class';
import {ShippingSaverAdType} from '../ads.types';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';
import Button from '../../ui/Buttons/Button';

export interface RibbonAdBannerProps {
    globalMessage: GlobalMessaging;
    ribbonAd: RibbonAd;
    shippingSaverAd: ShippingSaverAdType;
    userData: User;
}

export const RibbonAdBanner = ({globalMessage, ribbonAd, shippingSaverAd, userData}: RibbonAdBannerProps) => {
    const [errorMessage, setErrorMessage] = useState(``);
    const [promoApplied, setPromoApplied] = useState(false);
    const [showPromoNotMetModal, setShowPromoNotMetModal] = useState(false);
    const componentName = `RibbonAdBanner`;
    const gaItemListName: GaItemListName = `ribbon_ad_banner`;
    const orderItemsWorkflow: OrderItemsWorkflow = useService(`orderItemsWorkflow`);
    const ordersService: OrdersService = useService(`ordersService`);
    const promosService: PromosService = useService(`promosService`);
    const user = useMemo(() => new User(userData), [userData]);

    // Monitor order state to render "promo applied"
    useEffect(() => {
        if (ribbonAd?.pcode) {
            // Get current order for initial render
            ordersService
                .getCurrentOrder()
                .then((currentOrder) => {
                    evalPromoApplied(currentOrder);
                })
                .catch(() => {
                    // Error silently
                });

            // Subscribe to currentOrder$ for updates
            ordersService.currentOrder$.subscribe({
                next: (currentOrder) => {
                    evalPromoApplied(currentOrder);
                },
            });
        }
        // Only run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Adds shipping saver item to current order
     * @param promotion_id
     * @param promotion_name
     */
    const addShippingSaverToOrder = (promotion_id: string, promotion_name: string) => {
        // Build ItemToAdd
        const shippingSaverItem: ItemToAdd = {
            gaEcommerce: {
                item_list_name: gaItemListName,
                promotion_id,
                promotion_name,
            },
            item: SHIPPING_SAVER,
            unitsOrdered: 1,
        };

        // Send analytics
        gaLegacyCustomEvent({eventAction: `Add to Order Clicks`, eventCategory: `Ecommerce`, eventLabel: promotion_name});

        // Add shipping saver to order
        if (ImpUrl.onOrderDetailPage) {
            orderItemsWorkflow.addToOrderToast([shippingSaverItem], componentName, false).subscribe({
                error: (addToOrderToastErr: ImpError) => {
                    setErrorMessage(addToOrderToastErr.message);
                },
            });
        } else {
            orderItemsWorkflow.addToOrderModal([shippingSaverItem], componentName, false);
        }
    };

    /**
     * Applies clicked promo code to an order
     */
    const applyPromoCode = () => {
        if (ribbonAd.pcode) {
            promosService.applyPromoCode(ribbonAd.pcode, componentName).subscribe({
                error: (applyPromoCodeErr: ImpError) => {
                    if (applyPromoCodeErr.message.indexOf(`Promo code not valid`) >= 0) {
                        setShowPromoNotMetModal(true);
                    } else {
                        setErrorMessage(applyPromoCodeErr.message);
                    }
                },
            });
        }
    };

    /**
     * Evaluates if the displayed promo is applied on the user's order
     * @param currentOrder - Order to evaluate
     */
    const evalPromoApplied = (currentOrder: Order) => {
        if (currentOrder?.pcode && currentOrder.pcode === ribbonAd.pcode) {
            setPromoApplied(true);
        } else {
            setPromoApplied(false);
        }
    };

    /**
     * Returns desired GlobalMessaging display classes based on its attributes
     */
    const getGlobalMessageDisplayClasses = () => {
        let displayClasses = ``;
        displayClasses += globalMessage.ribbons.display_on_desktop ? `lg:tw-block` : `lg:tw-hidden`;
        displayClasses += globalMessage.ribbons.display_on_tablet ? `md:tw-block` : `md:tw-hidden`;
        displayClasses += globalMessage.ribbons.display_on_phone ? `tw-block` : `tw-hidden`;
        return displayClasses;
    };

    /**
     * Returns desired RibbonAd display classes based on its attributes
     * @param slice - Slice to evaluate
     */
    const getRibbonAdDisplayClasses = (slice: Slice) => {
        let displayClasses = ``;
        displayClasses += slice.primary.display_on_desktop ? `lg:tw-block` : `lg:tw-hidden`;
        displayClasses += slice.primary.display_on_tablet ? `md:tw-block` : `md:tw-hidden`;
        displayClasses += slice.primary.display_on_phone ? `tw-block` : `tw-hidden`;
        return displayClasses;
    };

    /**
     * Templates
     */
    if (globalMessage?.ribbons && !(globalMessage.ribbons.logged_out_only && user.isLoggedIn())) {
        return (
            <div className={`tw-w-full${getGlobalMessageDisplayClasses()}`}>
                <div
                    className="tw-py-2 tw-block tw-mt-auto tw-relative tw-text-center tw-no-underline"
                    style={{backgroundColor: globalMessage.ribbons.ribbon_background_color, color: globalMessage.ribbons.text_color}}
                >
                    <div className="tw-flex tw-flex-row tw-justify-center tw-items-center">
                        {globalMessage.ribbons.global_message_text}
                        {globalMessage.ribbons.global_message_url && (
                            <>
                                &nbsp;&nbsp;
                                <PromotionTracker
                                    as="span"
                                    promotion={{
                                        creative_name: globalMessage.ribbons.promotion_creative,
                                        creative_slot: 1,
                                        promotion_id: globalMessage.ribbons.promotion_id,
                                        promotion_name: globalMessage.ribbons.promotion_name,
                                    }}
                                >
                                    <a href={globalMessage.ribbons.global_message_url}>{globalMessage.ribbons.global_message_link_text}</a>
                                </PromotionTracker>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    } else if (ribbonAd) {
        return (
            <>
                <div className="tw-w-full">
                    <div className="tw-bg-gray-150 tw-block tw-mt-auto tw-relative tw-text-center tw-no-underline tw-py-2">
                        <div className="tw-flex tw-flex-row tw-justify-center tw-items-center">
                            {ribbonAd.content}
                            {ribbonAd.link_type_1 === `Apply Promo Link` ? (
                                <span className="tw-ml-5">
                                    {promoApplied ? (
                                        <span className="tw-text-green-200">
                                            <FontAwesomeIcon
                                                icon={faCheckCircle}
                                                className="tw-mr-2"
                                                suppressHydrationWarning
                                            />
                                            Code Applied
                                        </span>
                                    ) : (
                                        <PromotionTracker
                                            as="span"
                                            promotion={{
                                                creative_name: `Ribbon`,
                                                creative_slot: 1,
                                                promotion_id: ribbonAd.campaignId,
                                                promotion_name: `${ribbonAd.campaignName} - ${ribbonAd.link_text_1}`,
                                            }}
                                        >
                                            <Button
                                                onClick={applyPromoCode}
                                                size="sm"
                                                variant="link"
                                            >
                                                {ribbonAd.link_text_1}
                                            </Button>
                                        </PromotionTracker>
                                    )}
                                </span>
                            ) : (
                                <PromotionTracker
                                    as="span"
                                    promotion={{
                                        creative_name: `Ribbon`,
                                        creative_slot: 1,
                                        promotion_id: ribbonAd.campaignId,
                                        promotion_name: `${ribbonAd.campaignName} - ${ribbonAd.link_text_1}`,
                                    }}
                                >
                                    <a
                                        className="tw-ml-5"
                                        href={ribbonAd.link_url_1}
                                    >
                                        {ribbonAd.link_text_1}
                                    </a>
                                </PromotionTracker>
                            )}
                            {ribbonAd.link_type_2 === `Apply Promo Link` && (
                                <>
                                    <span className="tw-mx-2">|</span>
                                    {promoApplied ? (
                                        <span className="tw-text-green-200">
                                            <FontAwesomeIcon
                                                icon={faCheckCircle}
                                                className="tw-mr-2"
                                                suppressHydrationWarning
                                            />
                                            Code Applied
                                        </span>
                                    ) : (
                                        <PromotionTracker
                                            as="span"
                                            promotion={{
                                                creative_name: `Ribbon`,
                                                creative_slot: 2,
                                                promotion_id: ribbonAd.campaignId,
                                                promotion_name: `${ribbonAd.campaignName} - ${ribbonAd.link_text_2}`,
                                            }}
                                        >
                                            <Button
                                                onClick={applyPromoCode}
                                                size="sm"
                                                variant="link"
                                            >
                                                {ribbonAd.link_text_2}
                                            </Button>
                                        </PromotionTracker>
                                    )}
                                </>
                            )}
                            {ribbonAd.link_type_2 && ribbonAd.link_type_2 !== `Apply Promo Link` && (
                                <>
                                    <span className="tw-mx-2">|</span>
                                    <PromotionTracker
                                        as="span"
                                        promotion={{
                                            creative_name: `Ribbon`,
                                            creative_slot: 2,
                                            promotion_id: ribbonAd.campaignId,
                                            promotion_name: `${ribbonAd.campaignName} - ${ribbonAd.link_text_2}`,
                                        }}
                                    >
                                        <a href={ribbonAd.link_url_2}>{ribbonAd.link_text_2}</a>
                                    </PromotionTracker>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <ErrorModal
                    errorMsg={errorMessage}
                    onClose={() => setErrorMessage(``)}
                    show={!!errorMessage}
                />
                <PromoNotMetModal
                    campaignId={ribbonAd.campaignId}
                    hasLandingPage={ribbonAd.hasLandingPage}
                    onClose={() => setShowPromoNotMetModal(false)}
                    show={showPromoNotMetModal}
                />
            </>
        );
    } else if (shippingSaverAd && globalMessage?.shipping_saver) {
        return (
            <>
                {(shippingSaverAd === `shipping_saver` || shippingSaverAd === `renew_shipping_saver`) &&
                    globalMessage.shipping_saver.map((slice) => (
                        <React.Fragment key={slice.id}>
                            {((shippingSaverAd === `shipping_saver` && slice.slice_type === `shipping_saver`) ||
                                (shippingSaverAd === `renew_shipping_saver` && slice.slice_type === `shipping_saver_renewal`)) && (
                                <div className={`tw-w-full${getRibbonAdDisplayClasses(slice)}`}>
                                    <div
                                        className="tw-py-2"
                                        style={{
                                            backgroundColor: slice.primary.ribbon_background_color,
                                            color: slice.primary.text_color,
                                            display: `block`,
                                            textAlign: `center`,
                                        }}
                                    >
                                        <div className="tw-font-medium tw-m-0 tw-flex tw-justify-center tw-items-center">
                                            {slice.primary.text_large_view && (
                                                <span className="tw-hidden lg:tw-inline-block">{slice.primary.text_large_view}</span>
                                            )}
                                            {slice.primary.text_medium_view && (
                                                <span className="tw-hidden md:tw-inline-block lg:tw-hidden">
                                                    {slice.primary.text_medium_view}
                                                </span>
                                            )}
                                            {slice.primary.text_small_view && (
                                                <span className="tw-inline-block md:tw-hidden lg:tw-hidden">
                                                    {slice.primary.text_small_view}
                                                </span>
                                            )}
                                            <PromotionTracker
                                                as="span"
                                                promotion={{
                                                    creative_name: slice.primary.promotion_creative,
                                                    creative_slot: 1,
                                                    promotion_id: slice.primary.promotion_id,
                                                    promotion_name: `${slice.primary.promotion_name} - ${slice.primary.link_text}`,
                                                }}
                                            >
                                                <a
                                                    href={slice.primary.url}
                                                    style={{marginLeft: `20px`}}
                                                >
                                                    {slice.primary.link_text}
                                                </a>
                                            </PromotionTracker>
                                            <span className="tw-mx-2">|</span>
                                            <PromotionTracker
                                                as="span"
                                                promotion={{
                                                    creative_name: slice.primary.promotion_creative,
                                                    creative_slot: 2,
                                                    promotion_id: slice.primary.promotion_id,
                                                    promotion_name: `${slice.primary.promotion_name} - Add to Order`,
                                                }}
                                            >
                                                <Button
                                                    onClick={() =>
                                                        addShippingSaverToOrder(
                                                            slice.primary.promotion_id,
                                                            `${slice.primary.promotion_name} - Add to Order`,
                                                        )
                                                    }
                                                    size="sm"
                                                    variant="link"
                                                >
                                                    Add To Cart
                                                </Button>
                                            </PromotionTracker>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                <ErrorModal
                    errorMsg={errorMessage}
                    onClose={() => setErrorMessage(``)}
                    show={!!errorMessage}
                />
            </>
        );
    }
};
