import * as React from 'react';
import {useState} from 'react';

import {CheckBox} from '../../ui/forms/CheckBox/CheckBox';
import {ContentModal} from '../../ui/modals/ContentModal/ContentModal';
import {Radio} from '../../ui/forms/Radio/Radio';
import Button from '../../ui/Buttons/Button';

export interface AskToSaveRes {
    saveCard: boolean;
    setDefault: boolean;
}

interface AskToSaveCardModalProps {
    isPunchout: boolean;
    onClose: () => void;
    onContinue: (askToSaveRes: AskToSaveRes) => void;
    show: boolean;
}

export const AskToSaveCardModal = ({isPunchout, onClose, onContinue, show}: AskToSaveCardModalProps) => {
    const [saveCard, setSaveCard] = useState(false);
    const [setDefault, setSetDefault] = useState(false);

    /**
     * Calls user choice and closes modal
     */
    const onContinueHandler = () => {
        onContinue({saveCard, setDefault});
        onClose();
    };

    /**
     * Returns null response and closed modal
     */
    const onCloseHandler = () => {
        onContinue(null);
        onClose();
    };

    /**
     * Template
     */
    return (
        <ContentModal
            footer={
                <>
                    <Button
                        e2e="addCardButtonCancelBtn"
                        modal={true}
                        modalOrderChangeButton1={true}
                        onClick={onCloseHandler}
                        size="smToLg"
                        variant="outline-secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        className="lg:!tw-mb-1 !tw-ml-1"
                        e2e="addCardButtonContinue"
                        modal={true}
                        modalOrderChangeButton2={true}
                        onClick={onContinueHandler}
                        size="smToLg"
                        variant="secondary"
                    >
                        Continue
                    </Button>
                </>
            }
            onClose={onCloseHandler}
            show={show}
            title="Credit Card Options"
        >
            <>
                <label>Would you like to save this new credit card for future use?</label>
                <div className="tw-relative tw-z-[1] tw-block tw-min-h-[1.5rem] tw-pl-6">
                    <Radio
                        alias="askToSaveCardModal__saveCardFalse"
                        checked={saveCard === false}
                        className="tw-text-base tw-pt-1.5 tw-pb-1 tw-pl-3"
                        label="Don't save my card"
                        name="saveCard"
                        onChange={() => setSaveCard(false)}
                    />
                </div>
                <div className="tw-relative tw-z-[1] tw-block tw-min-h-[1.5rem] tw-pl-6 mb-4">
                    <Radio
                        alias="askToSaveCardModal__saveCardTrue"
                        checked={saveCard === true}
                        className="tw-text-base tw-pt-1.5 tw-pb-1 tw-pl-3"
                        label="Save card for future use"
                        name="saveCard"
                        onChange={() => setSaveCard(true)}
                    />
                </div>
                <div style={{height: `12px`}}>
                    {saveCard === true && !isPunchout && (
                        <CheckBox
                            alias="askToSaveCardModal__setDefault"
                            checked={setDefault}
                            label="Make this my default card"
                            name="setDefault"
                            onChange={(event) => setSetDefault(event.target.checked)}
                        />
                    )}
                </div>
            </>
        </ContentModal>
    );
};
