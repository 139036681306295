import {FetchService} from '../fetch/fetch.service';
import {MRORequestParams, VehicleSelectionEntity} from '../../shared/search/search.types';
import {SearchSuggestions} from '../../shared/search/search.types';
import {SentMessageInfo} from '../../shared/email/email.class';

export class SearchService {
    constructor(private _fetchService: FetchService) {}

    /**
     * Returns search suggestions for type-ahead
     * @param searchTerm - Text to provide suggestions for
     */
    public getSearchSuggestions(searchTerm: string): Promise<SearchSuggestions> {
        return this._fetchService.get<SearchSuggestions>(`/api/search/getSearchSuggestions?searchTerm=${encodeURIComponent(searchTerm)}`);
    }

    /**
     * Submit MRO request
     * @param mroRequestParams
     */
    public sendMRORequest(mroRequestParams: MRORequestParams) {
        return this._fetchService.post<SentMessageInfo>(`/api/search/sendMRORequest`, mroRequestParams);
    }

    /**
     * Get a list of VehicleSelectionEntities for a given type,make,model combination
     * @param type
     * @param make
     * @param model
     */
    public async vehicleSelector(type?: string, make?: string, model?: string): Promise<VehicleSelectionEntity[]> {
        // Build query string
        let params = ``;
        const paramsArray = [];
        if (type) {
            paramsArray.push(`type=${encodeURIComponent(type)}`);
        }
        if (make) {
            paramsArray.push(`make=${encodeURIComponent(make)}`);
        }
        if (model) {
            paramsArray.push(`model=${encodeURIComponent(model)}`);
        }
        if (paramsArray.length > 0) {
            params = `?${paramsArray.join('&')}`;
        }

        // Get vehicle information
        return this._fetchService.get(`/api/search/vehicleSelector${params}`);
    }
}
