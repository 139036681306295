import {AuthenticateParams, AuthenticateRes} from '../../shared/users/user.types';
import {FetchService} from '../fetch/fetch.service';
import {StandardJSONResponse} from '../../shared/tools/AS.types';

export class AuthenticationService {
    constructor(private _fetchService: FetchService) {}

    /**
     * Authenticates a user
     * @param loginEmail - User email address
     * @param loginPassword - User password
     * @param stayAuthed - Should session be non-transient
     * @param reauthenticate - If re-auth workflow should be used
     */
    public authenticateUser(loginEmail: string, loginPassword: string, stayAuthed: boolean, reauthenticate: boolean) {
        // Build params
        const logonParams = {
            login: loginEmail,
            loginType: `modal`,
            Password: loginPassword,
            reauthenticate,
        } as AuthenticateParams;

        // Add stayAuthed if requested
        if (stayAuthed === true) {
            logonParams.stayauthed = `on`;
        }

        // Post logon info
        return this._fetchService.post<AuthenticateRes>(`/logon`, logonParams, false, true);
    }

    /**
     * Change the user's password
     * @param passwordResetToken - Authorization token
     * @param newPassword - New password
     */
    public changePassword(passwordResetToken: string, newPassword: string) {
        return this._fetchService.post<void>(`/api/authentication/changePassword`, {newPassword, passwordResetToken}, true);
    }

    /**
     * Change the user's password
     * @param existingPassword - Authorization token
     * @param newPassword - New password
     * @param captcha - captcha token
     */
    public changeExistingPassword(existingPassword: string, newPassword: string, captcha: string) {
        return this._fetchService.post<StandardJSONResponse>(`/api/authentication/changeExistingPassword`, {
            newPassword,
            existingPassword,
            'g-recaptcha-response': captcha,
        });
    }
}
