import * as React from 'react';
import {faAngleDown} from '@fortawesome/pro-solid-svg-icons';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useRef, useState} from 'react';

import {AccountFinderModal} from '../../authentication/AccountFinderModal/AccountFinderModal';
import {ErrorModal} from '../../error-handling/ErrorModal';
import {HeaderDeals} from '../header-deals/HeaderDeals';
import {HeaderQuickAdd} from '../header-quick-add/HeaderQuickAdd';
import {ItemsService} from '../../../client/items/items.service';
import {ListsWorkflow} from '../../../client/lists/lists.workflow';
import {OrderItemsWorkflow} from '../../../client/order-items/order-items.workflow';
import {Overlay} from '../../../client/ui/components/Overlay';
import {PrismicItem, PrismicContent} from '../../cms/cms.types';
import {ProductCategories} from './ProductCategories';
import {TopProduct, WebcatMeta} from '../../items/item.class';
import {TopProducts} from './top-products/TopProducts';
import {UserStateService} from '../../../client/users/user-state.service';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';
import {ByServicePageMeta} from '../../shop-by-service/byService.type';
import Button from '../../ui/Buttons/Button';

export type SelectedMenu = `` | `dealsDropdown` | `productsDropdown` | `quickAddDropdown`;

interface MegaMenuProps {
    byServiceList?: ByServicePageMeta[];
    megaMenuContent: PrismicContent;
    rootCategories: WebcatMeta[];
    user: User;
}

export const MegaMenu = ({byServiceList, megaMenuContent, rootCategories, user}: MegaMenuProps) => {
    const [displayedMenu, setDisplayedMenu] = useState<SelectedMenu>(``);
    const [errorMessage, setErrorMessage] = useState(``);
    const [getTopProductsErr, setGetTopProductsErr] = useState(false);
    const [loadingTopProducts, setLoadingTopProducts] = useState(false);
    const [showAccountFinderModal, setShowAccountFinderModal] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [topProductsCol1, setTopProductsCol1] = useState<TopProduct[]>([]);
    const [topProductsCol2, setTopProductsCol2] = useState<TopProduct[]>([]);
    const headerQuickAddComponentName = `HeaderQuickAdd`;
    const itemsService: ItemsService = useService(`itemsService`);
    const listsWorkflow: ListsWorkflow = useService(`listsWorkflow`);
    const navBarRef = useRef(null);
    const orderItemsWorkflow: OrderItemsWorkflow = useService(`orderItemsWorkflow`);
    const userStateService: UserStateService = useService(`userStateService`);

    // Process queued actions
    useEffect(() => {
        if (userStateService.hasPendingAddToOrder(headerQuickAddComponentName)) {
            orderItemsWorkflow.addToOrderModal(userStateService.addToOrderItems, headerQuickAddComponentName, true);
        } else if (userStateService.hasPendingAddToList(headerQuickAddComponentName)) {
            listsWorkflow.addToList(
                userStateService.addToListAction,
                userStateService.addToListItems,
                headerQuickAddComponentName,
                `quick-add megamenu`,
                userStateService.addToListItemDesc,
                userStateService.addToListItemImage,
            );
        }
        // Only run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Close MegaMenu when clicked outside of component
        const handleClickOutside = (event: KeyboardEvent) => {
            if (navBarRef.current && !navBarRef.current.contains(event.target)) {
                close();
            }
        };
        document.addEventListener(`click`, handleClickOutside);

        // Close MegaMenu on escape key
        const handleEscapeKey = (event: KeyboardEvent) => {
            if (event.code === `Escape`) {
                close();
            }
        };
        document.addEventListener(`keydown`, handleEscapeKey);

        // Provide cleanup when MegaMenu is removed
        return () => {
            document.removeEventListener(`click`, handleClickOutside);
            document.removeEventListener(`keydown`, handleEscapeKey);
        };
    }, []);

    /**
     * Closes the mega menu
     */
    const close = () => {
        setDisplayedMenu(``);
        setShowOverlay(false);
    };

    /**
     * Retrieves top products with pricing
     */
    const getTopProducts = () => {
        setLoadingTopProducts(true);
        itemsService
            .getTopProducts()
            .then((getTopProductsRes) => {
                if (getTopProductsRes.dataset?.length > 0) {
                    itemsService
                        .addPricesForItemTypeArray(getTopProductsRes.dataset)
                        .then((addPricesForItemTypeArrayRes) => {
                            splitArray(addPricesForItemTypeArrayRes as TopProduct[]);
                        })
                        .catch(() => {
                            // Assuming error response means they can't see prices, so render without them
                            splitArray(getTopProductsRes.dataset);
                        });
                } else {
                    setLoadingTopProducts(false);
                }
            })
            .catch(() => {
                setLoadingTopProducts(false);
                setGetTopProductsErr(true);
            });
    };

    /**
     * Displays selected menu
     * @param selectedMenu
     */
    const openSelectedMenu = (selectedMenu: SelectedMenu) => {
        // If the same menu was already clicked on, close the MegaMenu
        if (selectedMenu === displayedMenu) {
            close();
        } else {
            setShowOverlay(true);

            // Retrieve top products first time quickAddDropdown is displayed
            if (selectedMenu === `quickAddDropdown` && user.isAccountLevel() && topProductsCol1.length === 0) {
                getTopProducts();
            }

            // Display selected menu
            setDisplayedMenu(selectedMenu);
        }
    };

    /**
     * Controls logic of which menuItems display
     * @param menuItem
     */
    const renderMenuItem = (menuItem: PrismicItem) => {
        if (
            (menuItem.logged_in_only && user.isLoggedIn()) ||
            (menuItem.catalog_restriction && !user.isCatalogRestricted()) ||
            (!menuItem.catalog_restriction && !menuItem.logged_in_only)
        ) {
            return (
                <li key={menuItem.link_text}>
                    <i className={`fa fa-${menuItem.font_awesome_icons} gray-400`} />
                    <a
                        href={menuItem.link}
                        id={menuItem.id}
                        rel={menuItem.seo_nofollow ? `nofollow` : undefined}
                    >
                        {menuItem.link_text}
                    </a>
                </li>
            );
        }
    };

    /**
     * Controls rendering logic for product categories UI
     */
    const renderProductCategories = () => {
        if (user.isLoggedIn()) {
            return (
                <div className="col-8">
                    <ProductCategories source="megaMenu" />
                </div>
            );
        } else if (rootCategories) {
            return (
                <div className="col-8">
                    <ul className="products">
                        {rootCategories.map((rootCategory) => (
                            <li key={rootCategory.id}>
                                <a href={`/browse/${rootCategory.categoryPath}`}>{rootCategory.name}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }
    };

    /**
     * Splits top products array into two columns
     * @param topProducts
     */
    const splitArray = (topProducts: TopProduct[]) => {
        setTopProductsCol1(topProducts.slice(0, 3));
        setTopProductsCol2(topProducts.slice(3, 6));
        setLoadingTopProducts(false);
    };

    /**
     * Template
     */
    return (
        <>
            <div
                className="tw-w-full tw-bg-gray-600 tw-p-0 tw-hidden lg:tw-block"
                ref={navBarRef}
            >
                <div className="tw-w-full tw-max-w-[1432px] tw-mx-auto tw-py-0 tw-px-8">
                    <div className="tw-flex tw-justify-between">
                        <div>
                            <ul className="tw-font-bold tw-text-sm tw-float-left tw-list-none tw-p-0 tw-m-0">
                                <li
                                    className={`tw-mr-[18px] tw-h-10 tw-leading-10 tw-float-left tw-text-gray-100 tw-cursor-pointer ${
                                        displayedMenu === `productsDropdown`
                                            ? `after:tw-relative after:tw-w-0 after:tw-h-0 after:tw-border-x-8 after:tw-border-x-transparent after:tw-border-b-[9px] after:tw-border-b-white after:tw-content-[''] after:tw-block after:tw-my-[-9px] after:tw-mx-auto after:tw-z-[1029]`
                                            : ``
                                    }`}
                                    id="menu-products"
                                    onClick={() => openSelectedMenu(`productsDropdown`)}
                                >
                                    <a
                                        className="tw-cursor-pointer tw-block tw-bg-transparent tw-text-gray-200 tw-font-bold tw-no-underline hover:tw-text-gray-100 hover:tw-no-underline"
                                        id="topNavProducts"
                                        role="menuitem"
                                    >
                                        PRODUCTS{' '}
                                        <FontAwesomeIcon
                                            icon={faAngleDown}
                                            suppressHydrationWarning={true}
                                        />
                                    </a>
                                </li>
                                <li
                                    className={`tw-mr-[18px] tw-h-10 tw-leading-10 tw-float-left tw-cursor-pointer tw-text-gray-100 ${
                                        displayedMenu === `quickAddDropdown`
                                            ? `after:tw-relative after:tw-w-0 after:tw-h-0 after:tw-border-x-8 after:tw-border-x-transparent after:tw-border-b-[9px] after:tw-border-b-white after:tw-content-[''] after:tw-block after:tw-my-[-9px] after:tw-mx-auto after:tw-z-[1029]`
                                            : ``
                                    }`}
                                    id="header_order"
                                    onClick={() => openSelectedMenu(`quickAddDropdown`)}
                                >
                                    <a
                                        className="tw-cursor-pointer tw-block tw-bg-transparent tw-text-gray-200 tw-font-bold tw-no-underline hover:tw-text-gray-100 hover:tw-no-underline"
                                        id="topNavQuickAdd"
                                        role="menuitem"
                                    >
                                        QUICK-ADD{' '}
                                        <FontAwesomeIcon
                                            icon={faAngleDown}
                                            suppressHydrationWarning={true}
                                        />
                                    </a>
                                </li>
                                <li className="tw-mr-[18px] tw-h-10 tw-leading-10 tw-float-left tw-cursor-pointer tw-text-gray-100">
                                    <a
                                        className="tw-cursor-pointer tw-block tw-bg-transparent tw-text-gray-200 tw-font-bold tw-no-underline hover:tw-text-gray-100 hover:tw-no-underline"
                                        href="/services"
                                        role="menuitem"
                                    >
                                        SERVICES
                                    </a>
                                </li>
                                <li className="tw-mr-[18px] tw-h-10 tw-leading-10 tw-float-left tw-cursor-pointer tw-text-gray-100">
                                    <a
                                        className="tw-cursor-pointer tw-block tw-bg-transparent tw-text-gray-200 tw-font-bold tw-no-underline hover:tw-text-gray-100 hover:tw-no-underline"
                                        href="/featured"
                                        id="topNavFeatured"
                                        role="menuitem"
                                    >
                                        FEATURED
                                    </a>
                                </li>
                                {user.hasDeals() && (
                                    <li
                                        className={`tw-mr-[18px] tw-h-10 tw-leading-10 tw-float-left tw-cursor-pointer tw-text-gray-100 ${
                                            displayedMenu === `dealsDropdown`
                                                ? `after:tw-relative after:tw-w-0 after:tw-h-0 after:tw-border-x-8 after:tw-border-x-transparent after:tw-border-b-[9px] after:tw-border-b-white after:tw-content-[''] after:tw-block after:tw-my-[-9px] after:tw-mx-auto after:tw-z-[1029]`
                                                : ``
                                        }`}
                                        id="menuDeals"
                                        onClick={() => openSelectedMenu(`dealsDropdown`)}
                                    >
                                        <a
                                            className="tw-cursor-pointer tw-block tw-bg-transparent tw-text-gray-200 tw-font-bold tw-no-underline hover:tw-text-gray-100 hover:tw-no-underline"
                                            id="topNavDeals"
                                            role="menuitem"
                                        >
                                            DEALS{' '}
                                            <FontAwesomeIcon
                                                icon={faAngleDown}
                                                suppressHydrationWarning={true}
                                            />
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div>
                            <ul className="tw-float-right tw-list-none tw-p-0 tw-m-0">
                                <li className="tw-pt-[9px] tw-h-10 tw-ml-4 tw-leading-10 tw-float-left tw-cursor-pointer">
                                    <a
                                        className="tw-cursor-pointer tw-block tw-bg-transparent tw-text-sm tw-text-gray-200 tw-font-normal tw-no-underline hover:tw-text-gray-100 hover:tw-no-underline"
                                        href="/order-history-items"
                                        id="topNavOrderHistory"
                                        rel="nofollow"
                                        role="menuitem"
                                    >
                                        Order History
                                    </a>
                                </li>
                                <li className="tw-pt-[9px] tw-h-10 tw-ml-4 tw-leading-10 tw-float-left tw-cursor-pointer">
                                    <a
                                        className="tw-cursor-pointer tw-block tw-bg-transparent tw-text-sm tw-text-gray-200 tw-font-normal tw-no-underline hover:tw-text-gray-100 hover:tw-no-underline"
                                        href="/lists"
                                        id="topNavMyLists"
                                        rel="nofollow"
                                        role="menuitem"
                                    >
                                        Lists
                                    </a>
                                </li>
                                <li className="tw-pt-[9px] tw-h-10 tw-ml-4 tw-leading-10 tw-float-left tw-cursor-pointer">
                                    <a
                                        className="tw-cursor-pointer tw-block tw-bg-transparent tw-text-sm tw-text-gray-200 tw-font-normal tw-no-underline hover:tw-text-gray-100 hover:tw-no-underline"
                                        href="/fileimport"
                                        rel="nofollow"
                                        role="menuitem"
                                    >
                                        File Import
                                    </a>
                                </li>
                                {!user.isPunchout && (
                                    <li className="tw-pt-[9px] tw-h-10 tw-ml-4 tw-leading-10 tw-float-left tw-cursor-pointer">
                                        <a
                                            className="tw-cursor-pointer tw-block tw-bg-transparent tw-text-sm tw-text-gray-200 tw-font-normal tw-no-underline hover:tw-text-gray-100 hover:tw-no-underline"
                                            href="/manageautoreorders"
                                            id="topNavAutoReorder"
                                            rel="nofollow"
                                            role="menuitem"
                                        >
                                            Auto-Reorder
                                        </a>
                                    </li>
                                )}
                                {!user.isCatalogRestricted() && (
                                    <li className="tw-pt-[9px] tw-h-10 tw-ml-4 tw-leading-10 tw-float-left tw-cursor-pointer">
                                        <a
                                            className="tw-cursor-pointer tw-block tw-bg-transparent tw-text-sm tw-text-gray-200 tw-font-normal tw-no-underline hover:tw-text-gray-100 hover:tw-no-underline"
                                            href="/flyer/full-line-catalog"
                                            id="topNavCatalog"
                                            rel="nofollow"
                                            role="menuitem"
                                        >
                                            Catalog
                                        </a>
                                    </li>
                                )}
                                <li className="tw-pt-[9px] tw-h-10 tw-ml-4 tw-leading-10 tw-float-left tw-cursor-pointer">
                                    <a
                                        className="tw-cursor-pointer tw-block tw-bg-transparent tw-text-sm tw-text-gray-200 tw-font-normal tw-no-underline hover:tw-text-gray-100 hover:tw-no-underline"
                                        href="/my-shop/locations"
                                        id="header_my_shop"
                                        role="menuitem"
                                    >
                                        My Shop
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {displayedMenu === `productsDropdown` && (
                    <div
                        className="tw-w-full tw-bg-white tw-absolute tw-z-[1026] tw-h-[499px] tw-transition-all tw-ease-in tw-duration-300 tw-overflow-hidden"
                        id="menuProductDropdown"
                    >
                        <div className="tw-max-w-[1432px] tw-mx-auto tw-w-full tw-py-0 tw-px-4">
                            <div className="tw-flex font-weight-bold mt-3 mb-3">
                                <div className="tw-grow">
                                    <ul className="tw-list-none tw-mt-[18px] tw-mx-0 tw-mb-0 tw-p-0 [&_li]:tw-float-left [&_li]:tw-ml-7 first:[&_li]:tw-ml-0 [&_i]:tw-mr-1">
                                        <li className="tw-ml-0 tw-float-left">
                                            {' '}
                                            <a
                                                className="tw-cursor-pointer tw-no-underline"
                                                href="/browse"
                                                id="shopAllLink"
                                                tabIndex={0}
                                            >
                                                <Button
                                                    className="tw-mt-[-7px] tw-mx-0 tw-mb-0"
                                                    size="sm"
                                                    variant="outline-secondary"
                                                >
                                                    Shop All
                                                </Button>
                                            </a>
                                        </li>
                                        {megaMenuContent?.body.map((slice) => (
                                            <React.Fragment key={slice.id}>
                                                {slice.slice_type === `mega_menu_header_links` && (
                                                    <>{slice.items.map((menuItem) => renderMenuItem(menuItem))}</>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </ul>
                                </div>
                                <div className="tw-text-right">
                                    <Button
                                        className="tw-text-gray-400 tw-bg-white tw-border-none"
                                        onClick={close}
                                    >
                                        <FontAwesomeIcon
                                            className="tw-text-gray-400 tw-text-5xl"
                                            icon={faTimes}
                                        />
                                    </Button>
                                </div>
                            </div>
                            <div />
                            <div className="tw-flex tw-flex-wrap tw-mt-6">
                                {renderProductCategories()}
                                <div className="tw-w-1/3 tw-relative">
                                    <div className="tw-m-0 tw-bg-gray-100 tw-p-4">
                                        {byServiceList?.length > 0 && (
                                            <div className="tw-mb-4">
                                                <div className="tw-text-xl tw-mb-2 tw-font-bold ">Shop by Service</div>
                                                <div className="tw-flex tw-flex-row tw-flex-wrap">
                                                    {byServiceList.map((byServiceMeta, index) => (
                                                        <div
                                                            className="tw-mb-1 tw-w-1/2"
                                                            key={index}
                                                        >
                                                            <a href={`/shopByService/${byServiceMeta._id}`}>{byServiceMeta.name}</a>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                        {megaMenuContent?.body.map((slice) => (
                                            <React.Fragment key={slice.id}>
                                                {slice.slice_type === `gray_block_links` && (
                                                    <div className="tw-mb-4">
                                                        <div className="tw-text-xl tw-font-normal tw-leading-[1.2] tw-mb-2">
                                                            {slice.primary.heading}
                                                        </div>
                                                        <div className="tw-flex tw-flex-row tw-flex-wrap">
                                                            {slice.items.map((menuItem, index) => (
                                                                <div
                                                                    className="tw-mb-1 tw-w-1/2"
                                                                    key={index}
                                                                >
                                                                    {menuItem.menu_icon && (
                                                                        <>
                                                                            <i className={`fa fa-${menuItem.menu_icon}`} />
                                                                            &nbsp;
                                                                        </>
                                                                    )}
                                                                    <a href={menuItem.link}>{menuItem.menu_link_text}</a>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {displayedMenu === `quickAddDropdown` && (
                    <div className="tw-bg-white tw-overflow-hidden tw-absolute tw-transition-all tw-duration-300 tw-w-full tw-z-[1100] !tw-h-fit">
                        <div
                            className="tw-max-w-[1432px] tw-py-0 tw-px-4 tw-w-full tw-mx-auto tw-mt-6"
                            id="quick-add-container"
                        >
                            <div className="tw-grid tw-grid-cols-4 tw-gap-3">
                                <div className="tw-w-full tw-col-span-1 tw-relative tw-border-r">
                                    <HeaderQuickAdd
                                        closeMegaMenu={close}
                                        componentName={headerQuickAddComponentName}
                                        openSelectedMenu={openSelectedMenu}
                                        setErrorMessage={setErrorMessage}
                                        user={user}
                                    />
                                </div>
                                <div className="tw-col-span-3 tw-relative tw-w-full">
                                    <div className="tw-grid tw-grid-cols-12">
                                        <div className="tw-col-span-11 tw-relative tw-w-full">
                                            <div className="tw-font-bold tw-text-2xl tw-mb-2">
                                                My Most Frequently Purchased Products&nbsp;
                                                <span className="tw-text-base tw-mb-2 tw-font-normal tw-pl-4">
                                                    <a
                                                        className="tw-no-underline"
                                                        href="/lists/topunt200?num=5"
                                                        rel="nofollow"
                                                    >
                                                        View All
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1 tw-text-right">
                                            <Button
                                                className="tw-bg-white tw-border-none"
                                                onClick={close}
                                            >
                                                <FontAwesomeIcon
                                                    className="tw-text-gray-400 tw-text-5xl"
                                                    icon={faTimes}
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="tw-mt-6">
                                        <TopProducts
                                            closeMegaMenu={close}
                                            getTopProductsErr={getTopProductsErr}
                                            loadingTopProducts={loadingTopProducts}
                                            isQuickAdd={true}
                                            setShowAccountFinderModal={setShowAccountFinderModal}
                                            topProductsCol1={topProductsCol1}
                                            topProductsCol2={topProductsCol2}
                                            user={user}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {displayedMenu === `dealsDropdown` && (
                    <div className="tw-w-full tw-bg-white tw-absolute tw-z-[1026] tw-transition-all tw-ease-in tw-duration-300 tw-border-b-0">
                        <HeaderDeals
                            closeMegaMenu={close}
                            setErrorMessage={setErrorMessage}
                        />
                    </div>
                )}
            </div>
            <AccountFinderModal
                onHide={() => setShowAccountFinderModal(false)}
                selectAccountMode={user.useSelectAccountMode()}
                show={showAccountFinderModal}
                user={user}
            />
            <ErrorModal
                errorMsg={errorMessage}
                onClose={() => setErrorMessage(``)}
                show={!!errorMessage}
            />
            <Overlay
                isMenu={true}
                show={showOverlay}
            />
        </>
    );
};
