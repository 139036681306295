import {isReactNativeMinVersion} from '../../shared/user-agent/user-agent.helpers';
import {ObsoleteItem, ScannedLocation} from '../../shared/my-shop/my-shop.class';
import {OrderLine} from '../../shared/orders/order.types';
import {RoDetails} from '../../server/job-costing/job-costing.class';
import {ScannerMode} from '../../shared/scanner/scanner.types';
import {User} from '../../shared/users/user.class';

export interface NativeMessage {
    action?: `openScanner`;
    currentOrderLine?: OrderLine;
    data?: string;
    event:
        | `closeScanner`
        | `currentUser`
        | `errorScanner`
        | `externalLink`
        | `navigate`
        | `offlineItemsUploadedSuccess`
        | `openScanner`
        | `removeItemSuccess`
        | `scanSuccess`
        | `setOfflineMode`
        | `submitObsoleteItemsSuccess`
        | `submitScannedLocationsSuccess`
        | `updateItemQtySuccess`;
    message?: string;
    orderLines?: number;
    orderNumber?: string;
    orderTotal?: string;
    page?: `Home` | `ObsoleteItems` | `ShopLocations`;
    repairOrder?: RoDetails;
    scannerMode?: ScannerMode;
    title?: string;
    user?: User;
}

export interface OfflineItem {
    item: string;
    quantity: string;
}

export interface WebMessage {
    event:
        | `closeScanner`
        | `scanBarcode`
        | `startOrderWorkflow`
        | `submitObsoleteItems`
        | `submitScannedLocations`
        | `updateItemQty`
        | `uploadOfflineItems`;
    itemNum?: string;
    newQty?: number;
    obsoleteItems?: ObsoleteItem[];
    offlineItems?: OfflineItem[];
    orderLine?: OrderLine;
    repairOrder?: RoDetails;
    scannedLocations?: ScannedLocation[];
    scannerMode?: ScannerMode;
}

export class UserAgent {
    /**
     * Returns true if user is using iOS
     */
    public static isiOS(): boolean {
        return /iPad|iPhone|iPod/.test(navigator.userAgent);
    }

    /**
     * Returns true if user is using the native app
     */
    public static isNativeApp(): boolean {
        return !!navigator.userAgent?.match(/gonative|Imperial React Native/);
    }

    /**
     * Returns true if user using the React Native app
     * @param minVersion - Optional required version number
     */
    public static isReactNative(minVersion?: string): boolean {
        const matchArray = navigator.userAgent?.match(/Imperial React Native\/?(\d{1,3}\.\d{1,3}\.\d{1,3})?/);
        return isReactNativeMinVersion(matchArray, minVersion);
    }

    /**
     * Sends webMessage to React Native environment
     * @param nativeMessage - Message to send
     */
    public static postNativeMessage(nativeMessage: NativeMessage) {
        window.ReactNativeWebView.postMessage(JSON.stringify(nativeMessage));
    }
}
